import React from 'react';

import logo from '../images/logo.png'

const Header = () => <header id="header">        
    <div className="header-inner wrapper clearfix">
                        
        <div id="logo" className="left-float">
            <a id="defaut-logo" className="logotype scroll-to" href="#home"><img src={logo} alt="Logo"/></a>
        </div>    
        
        <div className="menu right-float clearfix">

    <nav id="main-nav">
      
      <ul>
          <li className="current-menu-item"><a href="#home" className="scroll-to">Home</a></li>
        <li><a href="#music" className="scroll-to">Tracks</a></li>
          <li><a href="#about" className="scroll-to">About</a></li>
          <li><a href="#events" className="scroll-to">Events</a></li>
          <li><a href="#gallery" className="scroll-to">Gallery</a></li>
          <li><a href="#contact" className="scroll-to">Contact</a></li>
                </ul>

            </nav>

    <nav id="menu-controls">
      
      <ul>
        <li className="current-menu-item"><a href="#home" className="scroll-to"><span className="c-dot"></span><span className="c-name">Home</span></a></li>
        <li><a href="#music" className="scroll-to"><span className="c-dot"></span><span className="c-name">Tracks</span></a></li>
        <li><a href="#about" className="scroll-to"><span className="c-dot"></span><span className="c-name">About</span></a></li>
        <li><a href="#events" className="scroll-to"><span className="c-dot"></span><span className="c-name">Events</span></a></li>
        <li><a href="#gallery" className="scroll-to"><span className="c-dot"></span><span className="c-name">Gallery</span></a></li>
        <li><a href="#contact" className="scroll-to"><span className="c-dot"></span><span className="c-name">Contact</span></a></li>
              </ul>

            </nav>

        </div>
                
    </div>
</header>;

export default Header;
