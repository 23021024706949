import React from 'react'

import logo from '../images/logo.png';

const Loader = () => <div id="page-loader">
<div className="page-loader-inner">
    <div className="loader-logo"><img src={logo} alt="Logo"/></div>
    <div className="loader-icon"><span className="spinner"></span><span></span></div>
</div>
</div>;

export default Loader;