import * as React from "react"
import {Helmet} from "react-helmet"

import Header from '../components/Header';
import Loader from '../components/Loader';

const IndexPage = () => {
  return (
    <>
    <Helmet>
      <link href='http://fonts.googleapis.com/css?family=Open+Sans:300,400,800,600|Raleway:300,600,900' rel='stylesheet' type='text/css' />
      <link rel='stylesheet' id='default-style-css'  href='/css/style.css' type='text/css' media='all' />
      <link rel='stylesheet' id='flexslider-style-css'  href='/css/flexslider.css' type='text/css' media='all' />
      <link rel='stylesheet' id='easy-opener-style-css'  href='/css/easy-opener.css' type='text/css' media='all' />
      <link rel='stylesheet' id='jplayer-style-css'  href='/jplayer/jplayer.css' type='text/css' media='all' />
      <link rel='stylesheet' id='isotope-style-css'  href='/css/isotope.css' type='text/css' media='all' />
      <link rel="stylesheet" id='rsplugin-style-css' href="/rs-plugin/css/settings.css" type="text/css" media="all" />
      <link rel="stylesheet" id='fontawesome-style-css' href="/css/font-awesome.min.css" type="text/css" media="all" />
      <link rel='stylesheet' id='retina-style-css'  href='/css/retina.css' type='text/css' media='all' />
      <link rel='stylesheet' id='mqueries-style-css'  href='/css/mqueries.css' type='text/css' media='all' />

      <link rel="shortcut icon" href="/images/favicon.png"/>

      <link rel="stylesheet" href="/css/photoswipe/photoswipe.css"/> 
      <link rel="stylesheet" href="/css/photoswipe/default-skin.css"/> 
    </Helmet>
    <Loader/>
    <div id="page-content" className="fixed-header">
      <Header/>
    </div>
    </>
  )
}

export default IndexPage
